export const getLinks = (STOREFRONT_URL: string) => [
  {
    title: 'Nous connaître',
    sublinks: [
      {
        title: 'Qui sommes-nous ?',
        href: `${STOREFRONT_URL}/about/`,
      },
      {
        title: 'Comment ça marche ?',
        href: `${STOREFRONT_URL}/a/comment-ca-marche/`,
      },
      {
        title: 'Installateurs et assurances',
        href: `${STOREFRONT_URL}/a/referencement/`,
      },
      {
        title: 'Nos avis clients',
        href: `${STOREFRONT_URL}/a/nos-avis/`,
      },
      {
        title: 'Presse',
        href: `${STOREFRONT_URL}/a/presse/`,
      },
    ],
  },
  {
    title: 'Vous informer',
    sublinks: [
      {
        title: ' Centre de ressources',
        href: `${STOREFRONT_URL}/a/ressources/`,
      },
      {
        title: 'Notre blog',
        href: `${STOREFRONT_URL}/blog/`,
      },
      {
        title: "S'abonner à notre newsletter",
        href: `${STOREFRONT_URL}/a/newsletter`,
      },
      {
        title: 'Le Solaire en France',
        href: `${STOREFRONT_URL}/a/rentabilite-panneaux-solaires-en-region/`,
      },
    ],
  },
  {
    title: 'Nos solutions',
    sublinks: [
      {
        title: 'Acheter votre installation',
        href: `${STOREFRONT_URL}/a/offre/`,
      },
      {
        title: 'Louez votre installation',
        href: `${STOREFRONT_URL}/a/location-panneau-solaire/`,
      },
      {
        title: `Téléchargez l'appli Otovo`,
        href: `${STOREFRONT_URL}/a/application/`,
      },
      {
        title: 'Générateur de dossier ABF',
        href: `${STOREFRONT_URL}/a/outil-abf/`,
      },
      {
        title: 'Invitez un(e) proche',
        href: `${STOREFRONT_URL}/a/invitation/`,
      },
    ],
  },
  {
    title: 'Nous contacter',
    sublinks: [
      {
        title: 'Nous écrire',
        href: `${STOREFRONT_URL}/support/`,
      },
      {
        title: 'Rejoindre notre équipe',
        href: `${STOREFRONT_URL}/a/recrutement/`,
      },
      {
        title: 'Devenir installateur partenaire',
        href: 'https://installer.otovo.com/signup/fr/',
      },
    ],
  },
];
